import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout/layout';
import { Fonts, SiteMapContent, SiteMapHero } from '../global.css';
import BackToTop from '../components/back-to-top/back-to-top';
import axios from 'axios';
import NProgress from 'nprogress';
import { trialDetailsUrl } from '../constants/config';

class NotFound extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      canRender: false,
    };
  }

  componentDidMount() {
    let { pageContext } = this.props;

    if (typeof window !== 'undefined' && window.location) {
      let { href } = window.location;
      if (href) {
        // const split = href.split('/');
        //const match = split[split.length - 1] ? `${split[split.length -1]}`.match(/([A-Z]*)\d+/) : [];
        if (href.includes('/files2/')) {
          const split = href.split('/');
          href = split[split.length - 1].replace('gsk-', '');
        }
        const match = `${href}`.match(/([A-Z]*)([-_/]*)\d+([-_/]*)([A-Z]*)\d+/);

        if (match && match.length) {
          if (match[0].replace('/', '').trim() < 20000) {
            axios
              .get(
                trialDetailsUrl(`${match[0].replace('/', '').trim()}`, '&Id=')
              )
              .then(() => {
                NProgress.done();
                setTimeout(() => {
                  window.location.href = `/${
                    pageContext.langKey
                  }/trial-details/?id=${match[0]
                    .replace('/', '')
                    .trim()}&oldId=true`;
                }, 500);
              })
              .catch(() => {
                this.setState({ canRender: true });
              });
            return;
          } else {
            axios
              .get(trialDetailsUrl(`${match[0].replace('/', '').trim()}`))
              .then(() => {
                NProgress.done();
                setTimeout(() => {
                  window.location.href = `/${
                    pageContext.langKey
                  }/trial-details/?id=${match[0].replace('/', '').trim()}`;
                }, 500);
              })
              .catch(() => {
                this.setState({ canRender: true });
              });
            return;
          }
        }
      }
    }
    this.setState({ canRender: true });
  }

  render() {
    if (!this.state.canRender) {
      return null;
    }

    return (
      <Layout>
        <Fonts>
          <SiteMapHero>
            <div>
              <h1>Page not found</h1>
              <p>If you entered a web address please check it was correct.</p>
            </div>
          </SiteMapHero>
          <SiteMapContent>
            <div>
              You can also try{' '}
              <a style={{ paddingLeft: '1px' }} href={'/'}>
                home page
              </a>{' '}
              to find the information you need.
            </div>
          </SiteMapContent>
        </Fonts>
        <BackToTop />
      </Layout>
    );
  }
}

export default NotFound;

NotFound.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
  navigate: PropTypes.func,
};
